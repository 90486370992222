import { useState, useCallback, useEffect } from 'react';
import { Realtime } from 'ably';
import { getFormattedDateInTargetTimezone, getYesterdayInTargetTimezone } from '../dateUtils';

export function useSessionData() {
    const [measurements, setMeasurements] = useState([]);

    const fetchSessionData = useCallback(async () => {
        const sessionRef = localStorage.getItem('sessionRef');
        if (sessionRef) {
            try {
                const response = await fetch(`/.netlify/functions/getSession?ref=${sessionRef}`);
                const json = await response.json();
                const sessionData = json.data;
                const newMeasurements = sessionData.measurements || [];

                if (newMeasurements.length !== measurements.length) {
                    setMeasurements(newMeasurements);
                }
            } catch (error) {
                console.error('Error fetching session data:', error);
            }
        }
        return sessionRef;
    }, [measurements.length]);

    const sendNotification = useCallback(async (measurement) => {
        if (!measurement || !measurement.netConsumption) {
            return;
        }
        try {
            let foodTypeMessage;
            if (measurement.selectedFoodType === 'dry') {
                foodTypeMessage = 'dry food';
            } else if (measurement.selectedFoodType === 'wet') {
                foodTypeMessage = 'wet food';
            } else {
                foodTypeMessage = measurement.selectedFoodType;
            }
            await fetch('/.netlify/functions/home-assistant-notification', {
                method: 'POST',
                body: JSON.stringify({
                    message: `${measurement.netConsumption}g of ${foodTypeMessage}!`
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    }, []);

    const upsertSessionData = useCallback(async (newMeasurements) => {
        try {
            const response = await fetch('/.netlify/functions/upsertSession', {
                method: 'POST',
                body: JSON.stringify({
                    ref: localStorage.getItem('sessionRef'),
                    sessionData: { measurements: newMeasurements }
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const ref = (await response.json()).ref;
            localStorage.setItem('sessionRef', ref);
            setMeasurements(newMeasurements);
            await sendNotification(newMeasurements[0]);
        } catch (error) {
            console.error('Error saving session data:', error);
        }
    }, [sendNotification]);

    useEffect(() => {
        fetchSessionData();

        const fetchAblyToken = async () => {
            const response = await fetch(`/.netlify/functions/ably-token-request?clientId=food-tracker`);
            const tokenRequest = await response.json();
            return tokenRequest;
        };

        fetchAblyToken().then(tokenRequest => {
            const ably = new Realtime({
                authCallback: (tokenParams, callback) => {
                    callback(null, tokenRequest);
                },
                clientId: 'food-tracker'
            });

            const channel = ably.channels.get(`food-tracker:${localStorage.getItem('sessionRef')}`);
            channel.subscribe('new-entry', () => {
                fetchSessionData();
            });

            return () => {
                channel.unsubscribe('new-entry');
            };
        });

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                fetchSessionData();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [fetchSessionData]);

    const getTodayEaten = useCallback(() => {
        const today = getFormattedDateInTargetTimezone();
        const todayMeasurements = measurements.filter(measurement => {
            const measurementDate = getFormattedDateInTargetTimezone(new Date(measurement.timestamp));
            return (!measurement.nextDay && measurementDate === today) || (measurement.nextDay && measurementDate === getYesterdayInTargetTimezone());
        });
        return todayMeasurements.reduce((sum, measurement) => {
            return sum + (measurement.netConsumption || 0);
        }, 0);
    }, [measurements]);

    return {
        measurements,
        upsertSessionData,
        fetchSessionData,
        getTodayEaten,
    };
}