import React, { useState } from 'react';
import { Container, Tabs, Tab, Box } from '@mui/material';
import FoodTracker from './FoodTracker';
import SupplementTracker from './SupplementTracker';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function App() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="sm">
      <Tabs value={value} onChange={handleChange} aria-label="food and supplement tracker tabs">
        <Tab label="Food" />
        <Tab label="Supplements" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <FoodTracker />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SupplementTracker />
      </TabPanel>
    </Container>
  );
}

export default App;
