import React from 'react';
import { Button, Grid } from '@mui/material';

function MeasurementButtons({ handleSave, handleTare, handleConsumption }) {
    return (
        <>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    fullWidth
                    size="medium"
                    style={{ height: '100%' }}
                >
                    Save Scale
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleTare}
                    fullWidth
                    size="medium"
                    style={{ height: '100%' }}
                >
                    Tare Scale
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleConsumption}
                    fullWidth
                    size="medium"
                    style={{ height: '100%' }}
                >
                    Direct Add
                </Button>
            </Grid>
        </>
    );
}

export default React.memo(MeasurementButtons);