import { useCallback } from 'react';
import { askForPushPermission, PERMISSION_STATES, subscribeUserToPush } from '../utils';
import { savePushSubscription, sendPushNotification } from '../api';

export function usePushNotifications() {
    const handleSubscribeToPushNotifications = useCallback(async () => {
        const permissionResult = await askForPushPermission();

        if (permissionResult === PERMISSION_STATES.GRANTED) {
            const pushSubscription = await subscribeUserToPush();
            const sessionRef = localStorage.getItem('sessionRef');
            await savePushSubscription(sessionRef, pushSubscription);
        }
        return null;
    }, []);

    const handleSendPushNotification = useCallback(async (title, body) => {
        const sessionRef = localStorage.getItem('sessionRef');
        await sendPushNotification(sessionRef, title, body);
    }, []);

    return {
        handleSubscribeToPushNotifications,
        handleSendPushNotification,
    };
}