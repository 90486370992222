import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { Settings } from '@mui/icons-material';

const SupplementTracker = () => {
    const [supplementList, setSupplementList] = useState(() => {
        const stored = localStorage.getItem('supplementList');
        return stored ? JSON.parse(stored) : [];
    });

    const [supplementHistory, setSupplementHistory] = useState(() => {
        const stored = localStorage.getItem('supplementHistory');
        return stored ? JSON.parse(stored) : {};
    });

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [openModal, setOpenModal] = useState(false);
    const [newSupplement, setNewSupplement] = useState('');

    useEffect(() => {
        localStorage.setItem('supplementList', JSON.stringify(supplementList));
    }, [supplementList]);

    useEffect(() => {
        localStorage.setItem('supplementHistory', JSON.stringify(supplementHistory));
    }, [supplementHistory]);

    const getCurrentDaySupplements = () => {
        const dateKey = selectedDate.format('YYYY-MM-DD');
        return supplementHistory[dateKey] || {};
    };

    const handleCheckboxChange = (supplement, time) => {
        const dateKey = selectedDate.format('YYYY-MM-DD');
        const updatedHistory = { ...supplementHistory };
        if (!updatedHistory[dateKey]) {
            updatedHistory[dateKey] = {};
        }
        if (!updatedHistory[dateKey][supplement]) {
            updatedHistory[dateKey][supplement] = [];
        }
        const index = updatedHistory[dateKey][supplement].indexOf(time);
        if (index === -1) {
            updatedHistory[dateKey][supplement].push(time);
        } else {
            updatedHistory[dateKey][supplement].splice(index, 1);
        }
        setSupplementHistory(updatedHistory);
    };

    const handleAddSupplement = () => {
        if (newSupplement.trim() !== '' && !supplementList.includes(newSupplement)) {
            setSupplementList([...supplementList, newSupplement]);
            setNewSupplement('');
            setOpenModal(false);
        }
    };

    const handleRemoveSupplement = (supplement) => {
        setSupplementList(supplementList.filter((s) => s !== supplement));
        const updatedHistory = { ...supplementHistory };
        Object.keys(updatedHistory).forEach((date) => {
            delete updatedHistory[date][supplement];
        });
        setSupplementHistory(updatedHistory);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={(newDate) => setSelectedDate(newDate)}
                        format="DD-MM-YYYY"
                        sx={{ flexGrow: 1, mr: 2 }}
                    />
                    <IconButton color="primary" onClick={() => setOpenModal(true)}>
                        <Settings />
                    </IconButton>
                </Box>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Supplement</TableCell>
                                <TableCell align="center" sx={{ width: '100px' }}>AM</TableCell>
                                <TableCell align="center" sx={{ width: '100px' }}>PM</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {supplementList.map((supplement) => (
                                <TableRow key={supplement}>
                                    <TableCell>{supplement}</TableCell>
                                    <TableCell align="center">
                                        <Checkbox
                                            checked={getCurrentDaySupplements()[supplement]?.includes('AM') || false}
                                            onChange={() => handleCheckboxChange(supplement, 'AM')}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Checkbox
                                            checked={getCurrentDaySupplements()[supplement]?.includes('PM') || false}
                                            onChange={() => handleCheckboxChange(supplement, 'PM')}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                    <DialogTitle>Manage Supplements</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="New Supplement"
                            value={newSupplement}
                            onChange={(e) => setNewSupplement(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        {supplementList.map((supplement) => (
                            <Box key={supplement} display="flex" justifyContent="space-between" alignItems="center" my={1}>
                                <span>{supplement}</span>
                                <IconButton onClick={() => handleRemoveSupplement(supplement)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                        <Button onClick={handleAddSupplement}>Add</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </LocalizationProvider>
    );
};

export default SupplementTracker;