import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';

function SettingsDialog({ sessionRef, setSessionRef, isOpen, onClose, onSubscribeToPushNotifications, onTestPushNotifications, onOpenResetDialog }) {

    const handleUpdateSessionRef = () => {
        localStorage.setItem('sessionRef', sessionRef);
        setSessionRef('');
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a session reference to load the corresponding data.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Session Reference"
                    type="text"
                    fullWidth
                    value={sessionRef}
                    onChange={(e) => setSessionRef(e.target.value)}
                />
                <Button onClick={onSubscribeToPushNotifications} fullWidth color="warning">
                    Subscribe To Push Notifications
                </Button>
                <Button onClick={onTestPushNotifications} fullWidth color="warning">
                    Test Push Notifications
                </Button>
                <Button onClick={onOpenResetDialog} fullWidth color="secondary">
                    Delete All Data
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUpdateSessionRef} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(SettingsDialog);