import React, { useEffect } from 'react';
import { Box, Grid, TextField, Button } from '@mui/material';
const MeasurementInput = React.forwardRef(({ handleFoodType, selectedFoodType, resetInputWeight }, inputRef) => {

    useEffect(() => {
        if (resetInputWeight) {
            inputRef.current.value = '';
        }
    }, [resetInputWeight, inputRef]);

    return (
        <Grid item xs={12}>
            <Box display="flex" alignItems="center">
                <TextField
                    label="Enter weight (g)"
                    variant="outlined"
                    inputRef={inputRef}
                    inputProps={{ inputMode: 'numeric' }}
                    fullWidth
                />
                <Box display="flex" ml={1}>
                    <Button onClick={() => handleFoodType('dry')} style={{
                        minWidth: 'unset',
                        padding: '2px 2px',
                        border: selectedFoodType === 'dry' ? '2px solid #1976d2' : '2px solid transparent'
                    }}>
                        <img src='/images/dryfood.png' alt="Dry Food" style={{ width: 50, height: 50 }} />
                    </Button>
                    <Button onClick={() => handleFoodType('wet')} style={{
                        minWidth: 'unset',
                        padding: '2px 2px',
                        border: selectedFoodType === 'wet' ? '2px solid #1976d2' : '2px solid transparent'
                    }}>
                        <img src='/images/wetfood.png' alt="Wet Food" style={{ width: 50, height: 50 }} />
                    </Button>
                    <Button onClick={() => handleFoodType('chicken')} style={{
                        minWidth: 'unset',
                        padding: '2px 2px',
                        border: selectedFoodType === 'chicken' ? '2px solid #1976d2' : '2px solid transparent'
                    }}>
                        <img src='/images/chicken.png' alt="Chicken" style={{ width: 50, height: 50 }} />
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
});

export default MeasurementInput;
