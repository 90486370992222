import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function ResetDialog({ isResetDialogOpen, handleCloseResetDialog, handleReset }) {

    return (
        <Dialog
            open={isResetDialogOpen}
            onClose={handleCloseResetDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Reset"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to reset all measurements?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseResetDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleReset} color="primary" autoFocus>
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ResetDialog;
