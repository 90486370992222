import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { getFormattedDateInTargetTimezone } from '../dateUtils';

function PastDaysEatenTable({ measurements }) {

    const getEaten = (date) => {
        // Filter measurements for the given date and sum up netConsumption
        const filteredMeasurements = measurements.filter(measurement => {
            const measurementDate = getFormattedDateInTargetTimezone(new Date(measurement.timestamp));
            return measurementDate === date;
        });
        const todayEaten = filteredMeasurements.reduce((sum, measurement) => {
            return sum + (measurement.netConsumption || 0);
        }, 0);

        return todayEaten;
    };

    // Generate dates for the past seven days, including today
    const dates = [];
    for (let i = 0; i <= 6; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i); // descending dates
        dates.push(getFormattedDateInTargetTimezone(date));
    }

    // Data for the line chart
    const chartData = [...dates].reverse().map(date => ({ date, eaten: getEaten(date) }));

    const uData = chartData.map(data => data.eaten);
    const xLabels = [...dates].reverse();

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Consumption (g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dates.map(date => (
                            <TableRow key={date}>
                                <TableCell>{date}</TableCell>
                                <TableCell>{getEaten(date)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <LineChart
                maxWidth
                height={300}
                slotProps={{ legend: { hidden: true } }}
                series={[
                    { curve: "natural", data: uData, label: 'Consumption (g)' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
            />
        </div>
    );
}

export default PastDaysEatenTable;
