import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { getTimestampInTargetTimezone, getYesterdayInTargetTimezone, getFormattedDateInTargetTimezone } from '../dateUtils';

function ComparisonWithYesterday({ measurements }) {
    const getCumulativeEatenByHour = (date) => {
        // Filter measurements for the given date
        const filteredMeasurements = measurements.filter(measurement => {
            const measurementDate = getFormattedDateInTargetTimezone(new Date(measurement.timestamp));
            return measurementDate === date;
        });
        const cumulativeConsumptionByHour = Array(24).fill(null);

        // Calculate cumulative consumption for each hour
        let cumulative = 0;
        const accumulateConsumption = (hour) => {
            let consumptionInHour = 0;
            // Filter measurements for the current hour
            const measurementsInHour = filteredMeasurements.filter(measurement => {
                const measurementHour = getTimestampInTargetTimezone(new Date(measurement.timestamp)).split(',')[1].trim().split(':')[0]; // Extract hour from timestamp in London timezone
                return parseInt(measurementHour) === hour;
            });
            // Accumulate consumption for all measurements in the hour
            measurementsInHour.forEach(measurement => {
                consumptionInHour += measurement.netConsumption || 0;
            });
            cumulative += consumptionInHour;
            return cumulative;
        };

        for (let hour = 0; hour < 24; hour++) {
            const previousCumulative = cumulative;
            const newComulative = accumulateConsumption(hour);
            if (previousCumulative === newComulative) {
                cumulativeConsumptionByHour[hour] = null;
            } else {
                cumulativeConsumptionByHour[hour] = newComulative;
            }
        }

        return cumulativeConsumptionByHour;
    };

    // Get today's date and yesterday's date in Europe/London timezone
    const today = getFormattedDateInTargetTimezone();
    const yesterday = getYesterdayInTargetTimezone();

    // Data for the line chart
    const todayData = getCumulativeEatenByHour(today);
    const yesterdayData = getCumulativeEatenByHour(yesterday);

    const hourLabels = Array.from(Array(24).keys());

    return (
        <LineChart
            sx={{
                '& .MuiLineElement-series-Yesterday': {
                    strokeDasharray: '10 5',
                    strokeWidth: 4,
                },
            }}
            maxWidth
            height={300}
            slotProps={{ legend: { hidden: true } }}
            series={[
                {
                    id: 'Yesterday',
                    data: yesterdayData,
                    label: yesterday,
                    lineStyle: { dashArray: '5 5' },
                    showDot: false,
                    connectNulls: true,
                }, // Dotted line for yesterday
                {
                    id: 'Today',
                    data: todayData,
                    label: today,
                    showDot: (value, index) => todayData[index] !== null,
                    connectNulls: true,
                }
            ]}
            xAxis={[{ scaleType: 'point', data: hourLabels.map(String) }]}
        />
    );
}

export default ComparisonWithYesterday;
