import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Typography, IconButton, Box, Paper } from '@mui/material';
import { Undo, Settings, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getFormattedDateInTargetTimezone, getYesterdayInTargetTimezone, getCurrentHourInTargetTimezone } from './dateUtils';
import { useSessionData } from './hooks/useSessionData';
import { usePushNotifications } from './hooks/usePushNotifications';
import MeasurementInput from './components/MeasurementInput';
import MeasurementButtons from './components/MeasurementButtons';
import RecentMeasurements from './components/RecentMeasurements';
import ComparisonWithYesterday from './components/ComparisonWithYesterday';
import PastDaysEatenTable from './components/PastDaysEatenTable';
import SettingsDialog from './components/SettingsDialog';
import ResetDialog from './components/ResetDialog';

function FoodTracker() {
  const [showAllMeasurements, setShowAllMeasurements] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [selectedFoodType, setSelectedFoodType] = useState('dry');
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [sessionRef, setSessionRef] = useState('');
  const [resetInputWeight, setResetInputWeight] = useState(false);
  const inputRef = useRef('');

  const getInputWeight = () => {
    return inputRef.current ? inputRef.current.value : '';
  };

  const {
    measurements,
    upsertSessionData,
    fetchSessionData,
    getTodayEaten,
  } = useSessionData();

  const { handleSubscribeToPushNotifications, handleSendPushNotification } = usePushNotifications();

  useEffect(() => {
    async function doFetch() {
      const sessionRef = await fetchSessionData();
      setSessionRef(sessionRef);
    }
    doFetch();
    if (getCurrentHourInTargetTimezone() < 22) {
      setToggleState(false);
    }
  }, [fetchSessionData]);

  const handleToggleChange = useCallback((event) => {
    setToggleState(event.target.checked);
  }, []);

  const handleFoodType = useCallback((type) => {
    setSelectedFoodType(type);
  }, []);

  const isNextDay = useCallback(() => {
    return toggleState;
  }, [toggleState]);

  const handleTare = useCallback(() => {
    if (!getInputWeight().trim()) return;
    const currentWeightNum = parseInt(getInputWeight());
    const timestamp = new Date().toISOString();
    upsertSessionData([{
      weight: currentWeightNum,
      timestamp: timestamp,
      tare: true,
      nextDay: isNextDay()
    }, ...measurements]);
    setResetInputWeight(true);
  }, [isNextDay, measurements, upsertSessionData]);

  const handleSave = useCallback(() => {
    if (!getInputWeight().trim()) return;
    const currentWeightNum = parseInt(getInputWeight());
    const previousWeight = measurements[0]?.weight ?? null;
    let netConsumption = undefined;
    if (previousWeight !== null && currentWeightNum < previousWeight) {
      netConsumption = previousWeight - currentWeightNum;
      setResetInputWeight(true);
      const timestamp = new Date().toISOString();
      upsertSessionData([{
        weight: currentWeightNum,
        timestamp: timestamp,
        netConsumption: netConsumption,
        nextDay: isNextDay(),
        selectedFoodType: selectedFoodType
      }, ...measurements]);
    } else {
      handleTare();
    }
  }, [measurements, isNextDay, selectedFoodType, upsertSessionData, handleTare]);

  const handleConsumption = useCallback(() => {
    if (!getInputWeight().trim()) return;
    const netConsumption = parseInt(getInputWeight());
    const timestamp = new Date().toISOString();
    upsertSessionData([{
      netConsumption: netConsumption,
      timestamp: timestamp,
      consumption: true,
      nextDay: isNextDay(),
      selectedFoodType: selectedFoodType
    }, ...measurements]);
    setResetInputWeight(true);
  }, [isNextDay, selectedFoodType, measurements, upsertSessionData]);

  const handleReset = useCallback(() => {
    upsertSessionData([]);
    setIsResetDialogOpen(false);
    setResetInputWeight(true);
  }, [upsertSessionData]);

  const handleUndo = useCallback(() => {
    upsertSessionData(measurements.slice(1));
  }, [upsertSessionData, measurements]);

  const getRecentMeasurements = useCallback((showAll) => {
    const yesterday = getYesterdayInTargetTimezone();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const sevenDaysAgoFormatted = getFormattedDateInTargetTimezone(sevenDaysAgo);

    return measurements.filter(measurement => {
      const measurementDate = getFormattedDateInTargetTimezone(new Date(measurement.timestamp));
      return showAll ? measurementDate >= sevenDaysAgoFormatted : measurementDate >= yesterday;
    }).map(measurement => {
      const measurementDate = getFormattedDateInTargetTimezone(new Date(measurement.timestamp));
      let adjustedTimestamp = measurement.nextDay
        ? new Date(new Date(measurementDate).setDate(new Date(measurementDate).getDate() + 1)).setHours(0, 0, 0, 0)
        : measurement.timestamp;
      return { ...measurement, timestamp: new Date(adjustedTimestamp).toISOString() };
    }).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  }, [measurements]);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <ResetDialog
        isResetDialogOpen={isResetDialogOpen}
        handleCloseResetDialog={() => setIsResetDialogOpen(false)}
        handleReset={handleReset}
      />
      <SettingsDialog
        sessionRef={sessionRef}
        setSessionRef={setSessionRef}
        isOpen={isSettingsDialogOpen}
        onClose={() => {
          setIsSettingsDialogOpen(false)
          fetchSessionData()
        }}
        onSubscribeToPushNotifications={handleSubscribeToPushNotifications}
        onTestPushNotifications={() => handleSendPushNotification('test', 'message')}
        onOpenResetDialog={() => setIsResetDialogOpen(true)}
      />

      <Grid item xs={12}>
        <Box my={2}>
          <Paper elevation={3}>
            <Box p={2} display="flex" alignItems="center">
              <Typography variant="h5" gutterBottom style={{ flex: 1 }}>
                Today eaten: {getTodayEaten()} grams
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Grid>

      <MeasurementInput
        ref={inputRef}
        handleFoodType={handleFoodType}
        selectedFoodType={selectedFoodType}
        resetInputWeight={resetInputWeight}
      />

      <MeasurementButtons
        handleSave={handleSave}
        handleTare={handleTare}
        handleConsumption={handleConsumption}
      />

      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
          Measurements
        </Typography>
      </Grid>
      <Grid item xs={6} container direction="column">
        <Grid item xs={6} container justifyContent="flex-end">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={toggleState}
                  onChange={handleToggleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={getCurrentHourInTargetTimezone() < 22}
                />
              }
              label="As Next Day"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <IconButton color="primary" onClick={handleUndo}>
            <Undo />
          </IconButton>
          <IconButton color="primary" onClick={() => setShowAllMeasurements(!showAllMeasurements)}>
            {showAllMeasurements ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
          <IconButton color="primary" onClick={() => setIsSettingsDialogOpen(true)}>
            <Settings />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <RecentMeasurements
          measurements={getRecentMeasurements(showAllMeasurements)}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Comparison With Yesterday
        </Typography>
        <ComparisonWithYesterday measurements={getRecentMeasurements(true)} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Past Consumption
        </Typography>
        <PastDaysEatenTable measurements={getRecentMeasurements(true)} />
      </Grid>
    </Grid>
  );
}

export default FoodTracker;