
const getTimestampInTargetTimezone = (date = new Date()) => {
    return date.toLocaleString("en-GB", { timeZone: "Europe/London" });
}

const getCurrentHourInTargetTimezone = () => {
    const date = new Date();
    const londonTime = new Date(date.toLocaleString("en-GB", { timeZone: "Europe/London" }));
    return londonTime.getHours();
}

const getFormattedDateFromFormattedTimestamp = (timestamp) => {
    return timestamp.split(',')[0].split('/').reverse().join('-');
}

const getFormattedDateInTargetTimezone = (date = new Date()) => {
    return getFormattedDateFromFormattedTimestamp(getTimestampInTargetTimezone(date));
}

const getYesterdayInTargetTimezone = () => {
    return getFormattedDateInTargetTimezone(new Date(new Date().getTime() - 86400000));
}

export {
    getTimestampInTargetTimezone,
    getFormattedDateFromFormattedTimestamp,
    getFormattedDateInTargetTimezone,
    getYesterdayInTargetTimezone,
    getCurrentHourInTargetTimezone
}