// RecentMeasurements.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getFormattedDateInTargetTimezone, getTimestampInTargetTimezone } from '../dateUtils';

const RecentMeasurements = React.memo(({ measurements }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Consumed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {measurements.map((measurement, index) => (
                        <TableRow key={index} style={{ backgroundColor: measurement.netConsumption ? '#C8E6C9' : 'transparent' }}>
                            <TableCell>{getFormattedDateInTargetTimezone(new Date(measurement.timestamp))}{measurement.nextDay && ' (b/f)'}</TableCell>
                            <TableCell>{getTimestampInTargetTimezone(new Date(measurement.timestamp)).split(',')[1].split(' ')[1].slice(0, 5)}</TableCell>
                            <TableCell>{measurement.weight && `${measurement.weight}g`}</TableCell>
                            <TableCell>
                                {measurement.tare && 'Tare'}
                                {measurement.netConsumption && `${measurement.netConsumption}g`}
                                {measurement.selectedFoodType && ` ${measurement.selectedFoodType}`}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export default RecentMeasurements;